<template>
  <div>
    <base-header class="pb-6">
      <b-row class="py-4">
        <b-col lg="6" cols="7">
          <h6 class="h2 text-white d-inline-block mb-0">Dashboard View</h6>
        </b-col>
        <b-col lg="6" cols="7" style="display: flex; justify-content: flex-end">
          <base-button @click="back()">Kembali</base-button>
        </b-col>
      </b-row>
    </base-header>

    <b-container fluid class="mt--6">
      <b-row>
        <!-- akun -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Overview</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Akun</h5>

              <!-- content -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nama Kontak Person</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personName' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nomor Handphone</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personPhone' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Email Kontak Person</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personMail' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Password Akun</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personPassword' }}</span>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- umum -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Overview</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Umum</h5>

              <!-- content -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nama Perusahaan</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compName' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>ID Pendaftaran</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info._id' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Jenis Industri</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compType' }}</span>
                  </div>
                </div>

                <!-- <div
                  v-if="
                    info.umum.compType === 'Pertambangan Emas dan Tembaga' ||
                    info.umum.compType === 'Pertambangan Batu Bara' ||
                    info.umum.compType === 'Pertambangan Nikel'
                  "
                >
                
                  <div class="row mb-4">
                    <div class="col-lg-2">
                      <strong>Status Tambang</strong>
                    </div>
                    <div class="col-lg-10">
                      <span>{{ info.umum.ifTambang.status }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-2">
                      <strong>Jenis Kegiatan</strong>
                    </div>
                    <div class="col-lg-10">
                      <span>{{ info.umum.ifTambang.jenKegiatan }}</span>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-2">
                      <strong>Rencana penetapan titik penaatan</strong>
                    </div>
                    <div class="col-lg-10">
                      <a
                        :href="
                          info.umum.rencanaFile.path
                            ? `${baseURL}/${info.umum.rencanaFile.path}`
                            : ''
                        "
                        target="blank"
                      >
                        <span>
                          <i class="ni ni-cloud-download-95 text-primary"></i>
                        </span>
                        <span>{{
                          info.umum.rencanaFile.path === "" ||
                          info.umum.rencanaFile.path === undefined ||
                          info.umum.rencanaFile.path === null
                            ? " Belum ada  File Terupload"
                            : " File Terupload"
                        }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="row mb-4">
                    <div class="col-lg-2">
                      <strong
                        >Kondisi jaringan internet/GSM pada lokasi remote
                        area</strong
                      >
                    </div>
                    <div class="col-lg-10">
                      <span v-html="info.umum.ifTambang.kondisiInternet"></span>
                    </div>
                  </div>
                </div> -->

                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nama Penanggung Jawab SIUP</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.nameSIUP' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Lampiran SIUP</strong>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Surat Permohonan Koneksi</strong>
                  </div>
                  <div class="col-lg-10">
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Alamat Perusahaan</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compAddress' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Jalan</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compStreet' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Kabupaten / Kota</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compCityName' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Provinsi</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compProvinceName' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Telepon Kantor</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compPhone' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Email Kantor</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.umum.compMail' }}</span>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>

        <!-- identifikasi pencemaran -->
        <b-col xl="12">
          <b-card>
            <template v-slot:header>
              <!-- Subtitle -->
              <h6 class="surtitle">Overview</h6>
              <!-- Title -->
              <h5 class="h3 mb-0">Indentifikasi Pencemaran</h5>

              <!-- content -->
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nama Kontak Person</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personName' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Nomor Handphone</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personPhone' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Email Kontak Person</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personMail' }}</span>
                  </div>
                </div>
                <div class="row mb-4">
                  <div class="col-lg-2">
                    <strong>Password Akun</strong>
                  </div>
                  <div class="col-lg-10">
                    <span>{{ 'info.akun.personPassword' }}</span>
                  </div>
                </div>
              </div>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseHeader from "@/components/BaseHeader";

export default {
  components: {
    BaseHeader,
    RouteBreadCrumb,
  },
  data() {
    return {
      salesChart: {
        chartData: {
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Performance",
              data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
            },
          ],
        },
        extraOptions: chartConfigs.blueChartOptions,
      },
      ordersChart: {
        chartData: {
          labels: ["Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Sales",
              data: [25, 20, 30, 22, 17, 29],
            },
          ],
        },
      },
      dotsChart: {
        chartData: {
          labels: ["May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: "Performance",
              data: [10, 18, 28, 23, 28, 40, 36, 46, 52],
              pointRadius: 10,
              pointHoverRadius: 15,
              showLine: false,
            },
          ],
        },
        extraOptions: {
          dataset: {
            yAxes: [
              {
                gridLines: {
                  color: Charts.colors.gray[200],
                  zeroLineColor: Charts.colors.gray[200],
                },
              },
            ],
          },
        },
      },
      doughnutChart: {
        chartData: {
          labels: ["Danger", "Warning", "Success", "Primary", "Info"],
          datasets: [
            {
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
              backgroundColor: [
                Charts.colors.theme["danger"],
                Charts.colors.theme["warning"],
                Charts.colors.theme["success"],
                Charts.colors.theme["primary"],
                Charts.colors.theme["info"],
              ],
              label: "Dataset 1",
            },
          ],
        },
        extraOptions: {
          responsive: true,
          legend: {
            position: "top",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      },
      pieChart: {
        chartData: {
          labels: ["Danger", "Warning", "Success", "Primary", "Info"],
          datasets: [
            {
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
              backgroundColor: [
                Charts.colors.theme["danger"],
                Charts.colors.theme["warning"],
                Charts.colors.theme["success"],
                Charts.colors.theme["primary"],
                Charts.colors.theme["info"],
              ],
              label: "Dataset 1",
            },
          ],
        },
        extraOptions: {
          responsive: true,
          legend: {
            position: "top",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      },
      barChartStacked: {
        chartData: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "Dataset 1",
              backgroundColor: Charts.colors.theme["danger"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
            {
              label: "Dataset 2",
              backgroundColor: Charts.colors.theme["primary"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
            {
              label: "Dataset 3",
              backgroundColor: Charts.colors.theme["success"],
              data: [
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
                randomScalingFactor(),
              ],
            },
          ],
        },
        extraOptions: {
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          dataset: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
    };
  },
  methods: {
    back() {
      this.$router.push("/dashboards");
    },
  },
};
</script>
<style></style>
